<template>
  <!-- <v-container> -->
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="stats != ''" wrap justify-start>
      <v-flex xs12 sm6 lg3 text-left pa-2 v-if="type != 'SubAdmin'">
        <v-card rounded="lg" elevation="6" class="bg1" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 lg8 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{ stats.clients }} </span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link to="/users/user" style="text-decoration: none">
                    <span class="analyticsKey"> TOTAL USERS </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 pt-5 text-center align-self-center>
              <v-img
                src="./../../assets/iconsets/users.png"
                height="6vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 text-left pa-2>
        <v-card rounded="lg" elevation="6" class="bg2" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 lg8 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{ stats.transactions }}</span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link
                    to="/purchases/purchaseList"
                    style="text-decoration: none"
                  >
                    <span class="analyticsKey"> TOTAL PURCHASES </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 pt-5 text-center align-self-center>
              <v-img
                src="./../../assets/iconsets/purchase.png"
                height="6vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 text-left pa-2>
        <v-card rounded="lg" elevation="6" class="bg3" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 lg8 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue"> {{ stats.sellersactive }}</span>
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link
                    to="/sellers/seller"
                    style="text-decoration: none"
                  >
                    <span class="analyticsKey"> ACTIVE SELLERS </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 pt-5 text-center align-self-center>
              <v-img
                src="./../../assets/iconsets/sellers.png"
                height="6vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 text-left pa-2>
        <v-card rounded="lg" elevation="6" class="bg4" height="150px">
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 lg8 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsValue">
                    {{ stats.sellerspending }}</span
                  >
                </v-flex>
                <v-flex xs12 text-left>
                  <router-link
                    to="/pendingSellers"
                    style="text-decoration: none"
                  >
                    <span class="analyticsKey"> PENDING SELLERS </span>
                    <span class="itemArrow"> >> </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 pt-5 text-center align-self-center>
              <v-img
                src="./../../assets/iconsets/sellers.png"
                height="6vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg3 text-left pa-2 v-if="type != 'SubAdmin'">
        <v-card
          rounded="lg"
          elevation="6"
          class="bg5"
          height="150px"
          @click="dialog = true"
        >
          <v-layout wrap justify-end pa-4>
            <v-flex xs9 lg8 xl7 text-left>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="analyticsKey"> SEND NOTIFICATION </span>
                  <span class="itemArrow"> >> </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 xl4 pt-5 text-center align-self-center>
              <v-img
                src="./../../assets/iconsets/program.png"
                height="6vh"
                contain
              ></v-img>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-row>
      <v-col cols="12">
        <div id="chart" v-if="flag">
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-text class="pa-5">
            <h3
              class="
                title
                blue-grey--text
                text--darken-2
                font-weight-regular
                mb-4
              "
            >
              Last 10 Purchases
              <span
                ><v-btn
                  @click="$router.push('/purchases/purchaseList')"
                  text
                  color="blue"
                  >View All</v-btn
                ></span
              >
            </h3>
            <v-flex xs12 py-2 v-for="(item, i) in user" :key="i">
              <v-card flat rounded="xl" color="#F5F7FC" outlined>
                <v-layout px-4 wrap justify-center>
                  <v-flex xs12>
                    <v-layout wrap justify-center>
                      <v-flex xs3 sm2 pa-2 align-self-center>
                        <v-img
                          :src="baseURL + item.buyer.image"
                          height="80px"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="#FF4343"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-flex>
                      <v-flex pt-4 xs9 sm10 text-left>
                        <v-layout wrap justify-center>
                          <v-flex xs12 sm6>
                            <v-layout wrap justify-center>
                              <v-flex xs12 text-left>
                                <!-- <router-link
                            :to="'/Seller/Product/' + item.product._id"
                          > -->
                                <span style="font-size: 16px; color: #808080">
                                  {{ item.buyer.firstname }}
                                  {{ item.buyer.lastname }}
                                  <!-- <span v-if="item.product.productname.length > 25">
                                ...
                              </span> -->
                                </span>
                                <!-- </router-link> -->
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span style="font-size: 14px; color: #000000">
                                  Email: {{ item.email }}
                                </span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span style="font-size: 14px; color: #000000">
                                  Phone: {{ item.mobile }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs4 sm2>
                            <v-layout wrap justify-center>
                              <v-flex xs12 text-left>
                                <span style="font-size: 16px; color: #808080">
                                  Productname
                                </span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span style="font-size: 14px; color: #000000">
                                  {{ item.productname }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs4 sm2>
                            <v-layout wrap justify-center>
                              <v-flex xs12 text-left>
                                <span style="font-size: 16px; color: #808080">
                                  Method
                                </span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span style="font-size: 14px; color: #000000">
                                  {{ item.method }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs4 sm2>
                            <v-layout wrap justify-center>
                              <v-flex xs12 text-left>
                                <span style="font-size: 16px; color: #808080">
                                  Amount
                                </span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span style="font-size: 14px; color: #000000">
                                  {{ item.amount }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      :retain-focus="false"
      v-model="dialog"
      persistent
      max-width="600px"
      :key="dialog"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Notification</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  label="Notification Title"
                  :rules="[rules.required]"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="message"
                  label="Notification Message"
                  :rules="[rules.required]"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="addNotification()">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <!-- </v-container> -->
</template>

<script>
const gradients = [
  ["#6c757d"],
  ["#6c757d"],
  ["#6c757d", "#6c757d", "#6c757d"],
  ["#21c1d6", "21c1d6"],
  ["#21c1d6", "#21c1d6", "#21c1d6"],
  ["#21c1d6", "#21c1d6", "#21c1d6"],
];

const gradients2 = [
  ["#1e88e5"],
  ["#1e88e5"],
  ["#1e88e5", "#1e88e5", "#1e88e5"],
  ["#1e88e5", "#1e88e5"],
  ["#1e88e5", "#1e88e5", "#1e88e5"],
  ["#1e88e5", "#1e88e5", "#1e88e5"],
];
import axios from "axios";
export default {
  name: "BasicDashboard",
  data: () => ({
    fill: true,
    stats: "",
    user: [],
    type: "",
    flag: false,
    dialog: false,
    graph: [],
    gradient: gradients[4],
    gradients,
    padding: 8,
    radius: 50,
    value: [0, 2, 5, 9, 5, 10, 3, 5, 1, 8, 2, 9, 0],
    width: 1,

    fill2: true,
    appLoading: false,
    gradient2: gradients2[4],
    gradients2,
    radius2: 0,
    padding2: 0,
    value2: [1, 2, 5, 9, 5, 10, 3, 5, 1, 8, 2, 9, 4],
    width2: 4,
    showsnackbar: false,
    msg: null,
    tasks: [
      {
        done: false,
        text: "Check emails of Mr.Shah",
      },
      {
        done: false,
        text: "Give salary to employee",
      },
    ],
    task: null,
    selected: [2],
    status: [
      { value: "Last Year", text: "Last Year" },
      { value: "Last 6 Months", text: "Last 6 Months" },
      { value: "Last 4 Months", text: "Last 4 Months" },
      { value: "Last 3 Months", text: "Last 3 Months" },
    ],
    rules: {
      required: (value) => !!value || "Required.",
    },
    items: [
      {
        action: "15 min",
        headline: "Brunch this weekend?",
        title: "Ali Connors",
        subtitle:
          "I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
      },
      {
        action: "2 hr",
        headline: "Summer BBQ",
        title: "me, Scrott, Jennifer",
        subtitle: "Wish I could come, but I'm out of town this weekend.",
      },
      {
        action: "6 hr",
        headline: "Oui oui",
        title: "Sandra Adams",
        subtitle: "Do you have Paris recommendations? Have you ever been?",
      },
      {
        action: "12 hr",
        headline: "Birthday gift",
        title: "Trevor Hansen",
        subtitle:
          "Have any ideas about what we should get Heidi for her birthday?",
      },
      {
        action: "18hr",
        headline: "Recipe to try",
        title: "Britta Holt",
        subtitle:
          "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
      },
    ],
    series: [
      {
        name: "Transaction Rs",
        data: [],
      },
      {
        name: "Client",
        data: [],
      },
      {
        name: "Product",
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      // yaxis: {
      //   title: {
      //     text: "Rs (thousands)",
      //   },
      // },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return +val;
          },
        },
      },
    },
    title: null,
    message: "",
  }),
  computed: {
    completedTasks() {
      return this.tasks.filter((task) => task.done).length;
    },
    progress() {
      return (this.completedTasks / this.tasks.length) * 100;
    },
    remainingTasks() {
      return this.tasks.length - this.completedTasks;
    },
  },
  mounted() {
    this.loadStats();
  },
  methods: {
    create() {
      this.tasks.push({
        done: false,
        text: this.task,
      });
      this.task = null;
    },
    loadStats() {
      this.appLoading = true;
      axios({
        url: "dashboard/admin",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.stats = response.data.data.stats;
          this.type = response.data.type;
          this.user = response.data.data.lasttenpurchases;
          this.graph = response.data.data.graph;
          for (var i = 0; i < this.graph.length; i++) {
            this.chartOptions.xaxis.categories.push(
              this.graph[i].month + this.graph[i].year
            );
            this.series[0].data.push(this.graph[i].transaction);
            this.series[1].data.push(this.graph[i].clientcount);
            this.series[2].data.push(this.graph[i].productcount);
          }
          this.flag = true;
        })
        .catch((e) => {
          this.appLoading = false;
          this.errors.push(e);
        });
    },
    addNotification() {
      var data = {};
      data["title"] = this.title;
      data["message"] = this.message;
      axios({
        url: "/send/notification/all/customers",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.title = null;
            (this.message = ""), (this.dialog = false);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.analyticsKey {
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 1px;
}
.analyticsValue {
  font-size: 22px;
  color: #ffffff;
}
.itemArrow {
  font-size: 12px;
  color: #ffffff;
}
.bg1 {
  background-image: linear-gradient(to right, #00f2fe 0%, #4facfe 100%);
}
.bg2 {
  background-image: linear-gradient(to right, #f093fb 0%, #f5576c 100%);
}
.bg3 {
  background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
}
.bg4 {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}
.bg5 {
  background-image: linear-gradient(to right, #cae230 0%, #0bc60b 100%);
}
</style>
